declare const gsap: any;
declare const $: any;
const tl = gsap.timeline();

function effectTop(...element:any){
    element.forEach(function(item:any){
        if (item && item.length === undefined){
            tl.set(item, {
                y: 100,
                opacity: 0
            })
        }else if (item && item.length > 0){
            tl.set(item, {
                y: 100,
                opacity: 0
            })
        }
    })
}
function effectBottom(...element:any){
    element.forEach(function(item:any){
        if (item && item.length === undefined){
            tl.set(item, {
                y: -100,
                opacity: 0
            })
        }else if (item && item.length > 0){
            tl.set(item, {
                y: -100,
                opacity: 0
            })
        }
    })
}
function effectLeft(...element:any){
    element.forEach(function(item:any){
        if (item && item.length === undefined){
            tl.set(item, {
                x: -100,
                opacity: 0
            })
        }else if (item && item.length > 0){
            tl.set(item, {
                x: -100,
                opacity: 0
            })
        }
    })
}
function effectRight(...element:any){
    element.forEach(function(item:any){
        if (item && item.length === undefined){
            tl.set(item, {
                x: 100,
                opacity: 0
            })
        }else if (item && item.length > 0){
            tl.set(item, {
                x: 100,
                opacity: 0
            })
        }
    })
}

function effectScale(...element:any){
    element.forEach(function(item:any){
        if (item.length === undefined){
            item && tl.set(item, {
                scale: 0,
                opacity: 0
            })
        }else if (item && item.length > 0){
            tl.set(item, {
                scale: 0,
                opacity: 0
            })
        }
    })
}

export const gsapInitScroll = function () {
    initHeader();

    if (document.getElementById('indexPage')) {
        effectTop(
            document.querySelector(".idx-1__title"), 
            document.querySelector(".idx-1-more"), 
            document.querySelector(".idx-1__desc"),
            document.querySelectorAll('.idx-6-slider .item-logo'), 
            document.querySelectorAll('.idx-7-ls>li .name'), 
            $('.idx-7-ls>li .text'),
            $('.idx-5-ls .item'),
            $('.idx-5-all'),
        );

        // $('.idx-2 .quote') && tl.set($('.idx-2 .quote'), {
        //     x: '100%',
        //     opacity: 0,
        // })

        effectRight($('.idx-2 .quote'));

        $('.idx-3__thumbnail') && tl.set($('.idx-3__thumbnail'), {
            scale: 2
        })

        let idx4Odd = document.querySelector('.idx-4--section:nth-of-type(2n+1)');
        let idx4Even = document.querySelector('.idx-4--section:nth-of-type(2n)');

        idx4Odd && tl.set([idx4Odd.querySelector('.idx-4__bg'), idx4Odd.querySelector('.idx-4-content')], {
            x: '50vw',
            opacity: 0,
        })
        idx4Even && tl.set([idx4Even.querySelector('.idx-4__bg'), idx4Even.querySelector('.idx-4-content')], {
            x: '-50vw',
            opacity: 0,
        })


        effectLeft(document.querySelector('.idx-6-content .title'), document.querySelector('.idx-6-content .desc'), 
            document.querySelector('.idx-7__title'), document.querySelector('.idx-7__desc'));
        

        $(".idx-7-ls>li") && tl.set($(".idx-7-ls>li"), {
            scale: 2,
            opacity: 0
        })
    }
    else if(document.getElementById('aboutPage')){

        effectTop(
            document.querySelector('.ab-1__title'),
            document.querySelector('.ab-1-wrapper'),
            document.querySelector('.ab-3__title'),
            document.querySelector('.ab-3-desc'),
            document.querySelector('.ab-4__title'),
            document.querySelector('.ab-4__desc'),
            document.querySelectorAll('.ab-4-wrapper .partner-ls'),
            document.querySelector('.ab-5__title'),
            document.querySelector('.ab-5__desc')
        );

        document.querySelector('.ab-3 .timeline__bg img') && tl.set(document.querySelector('.ab-3 .timeline__bg img'), {
            scale: 2,
        })

        effectLeft(
            document.querySelectorAll('.ab-2-wrapper .item:nth-of-type(2n + 1) .item-content'),
            document.querySelectorAll('.ab-2-wrapper .item:nth-of-type(2n) .item__thumbnail'),
        );
        effectRight(
            document.querySelectorAll('.ab-2-wrapper .item:nth-of-type(2n) .item-content'), 
            document.querySelectorAll('.ab-2-wrapper .item:nth-of-type(2n + 1) .item__thumbnail'),
        );
            
        effectScale(
            document.querySelectorAll('.ab-5-wrapper .opt'), 
            document.querySelectorAll('.ab-5-wrapper .wrapper-item .item-slide')
        );
        
        effectBottom(
            document.querySelectorAll('.ab-3-timeline .timeline-ls .item:nth-of-type(2n + 1)')
        );
        effectTop(
            document.querySelectorAll('.ab-3-timeline .timeline-ls .item:nth-of-type(2n)'),                            
        );
    }

    else if (document.getElementById('productsPage')){
        effectTop(
            document.querySelector('.pro-1__title'),
            document.querySelectorAll('.pro-1 .wrapper-ls .item')
        );

    }
    else if (document.getElementById('newsPage')){
        effectTop(
            document.querySelector('.news-1__title'),
            document.querySelectorAll('.news-1-wrapper .wrapper-ls .item .item-content>*'),
        );
        effectRight(
            document.querySelectorAll('.news-1-wrapper .wrapper-ls .item .item-thumbnail')
        );
    }
    else if (document.getElementById('contactPage')){
        effectTop(
            document.querySelector('.ct-1__title'),
            document.querySelectorAll('.ct-1-wrapper .content-infor .item .infor>*'),

            document.querySelector('.ct-2-box'),
            document.querySelector('.ct-2-box .ct-2__title'),

            document.querySelectorAll('.ct-2-box .group-nav'),
            document.querySelectorAll('.ct-2-box .group-form'),

            document.querySelectorAll('.ct-3-wrapper .question-ls .item'),
        );
        effectRight(
            document.querySelector('.ct-1-wrapper .maps'),
        );
                
        effectLeft(
            document.querySelector('.ct-1-wrapper .content .name'),
            document.querySelector('.ct-1-wrapper .content .addr'),

            document.querySelector('.ct-3__title'),
        );

        effectScale(
            document.querySelectorAll('.ct-1-wrapper .content-infor .item .icon'),
        );
    }
    
    if (document.querySelector('.infor-template')){
        let title = document.querySelector('.infor-template__title');
        let desc = document.querySelector('.infor-template__desc');

        let wrapper = document.querySelector('.infor-template-wrapper');
        let nameWrapper = wrapper?.querySelectorAll('.name');
        let descWrapper = wrapper?.querySelectorAll('.desc>*');
        let moreWrapper = wrapper?.querySelectorAll('.more');
        let thumbWrapper = wrapper?.querySelectorAll('.item__thumbnail');
        let thumbVideoWrapper = wrapper?.querySelectorAll('.item__thumbnail--video');

        effectTop(
            title, desc, nameWrapper, descWrapper, moreWrapper
        );
        
        effectLeft(
            document.querySelectorAll('.infor-template-wrapper .item:nth-of-type(2n + 1) .item__thumbnail'),
            document.querySelectorAll('.infor-template-wrapper .item:nth-of-type(2n + 1) .item__thumbnail--video')
        );

        effectRight(
            document.querySelectorAll('.infor-template-wrapper .item:nth-of-type(2n) .item__thumbnail'),
            document.querySelectorAll('.infor-template-wrapper .item:nth-of-type(2n) .item__thumbnail--video')
        );
    }

    effectTop(
        document.querySelectorAll('.pagination'),
        document.querySelectorAll('.effect-top'),
        document.querySelectorAll('.effect-top-stagger>*')
    );

    effectLeft(
        document.querySelectorAll('.navbar'),
        document.querySelectorAll('.effect-left'),
        document.querySelectorAll('.effect-left-stagger>*')
    );

    effectRight(
        document.querySelectorAll('.breadcrumbs>ul>li'),
        document.querySelectorAll('.effect-right'),
        document.querySelectorAll('.effect-right-stagger>*'),
    );

    effectBottom(
        document.querySelectorAll('.effect-bottom'),
        document.querySelectorAll('.effect-bottom-stagger>*'),
    );

    initSectionContact();
    initFooter();
}

function initHeader() {
    let header = document.querySelector('.header');
    if (!header) {
        return false;
    }

    effectLeft(
        header.querySelector('.logo'),
    );

    effectRight(
        header.querySelector('.languages')
    );
        
    effectTop(
        header.querySelectorAll('.header-navbar>ul>li'),
    );
}

function initSectionContact() {
    let element = document.querySelector('.section-contact');
    if (element) {
        effectTop(
            element.querySelector('.section-contact-form')
        );
        effectLeft(
            element.querySelector('.content-desc'),
            element.querySelector('.content-name'),
            element.querySelectorAll('.content-footer .item'),
        );
    }
}

function initFooter() {
    let element = document.querySelector('.footer');
    if (element) {
        element.querySelector('.footer-top .logo') && tl.set([element.querySelector('.footer-top .logo')], {
            x: 100,
            opacity: 0
        })
        element.querySelectorAll('.footer-navbar>ul>li').length > 0 && tl.set(element.querySelectorAll('.footer-navbar>ul>li'), {
            y: -40,
            opacity: 0,
        })
        element.querySelectorAll('.footer-information .item').length > 0 && tl.set(element.querySelectorAll('.footer-information .item'), {
            x: 120,
            opacity: 0
        })
        element.querySelector('.footer-copyright') && tl.set([element.querySelector('.footer-copyright')], {
            scale: 0
        })
    }
}

// export const gsapInitScroll = function () {
//     initHeader();

//     if (document.getElementById('indexPage')) {
//         tl.set([document.querySelector(".idx-1__title"),
//         document.querySelector(".idx-1-more"),
//         document.querySelector(".idx-1__desc")], {
//             y: 300,
//             opacity: 0
//         })
//         tl.set($('.idx-2 .quote'), {
//             opacity: 0,
//             x: '100%'
//         })

//         tl.set($('.idx-3__thumbnail'), {
//             scale: 2
//         })

//         let idx4Odd = document.querySelector('.idx-4--section:nth-of-type(2n+1)');
//         let idx4Even = document.querySelector('.idx-4--section:nth-of-type(2n)');

//         idx4Odd && tl.set([idx4Odd.querySelector('.idx-4__bg'), idx4Odd.querySelector('.idx-4-content')], {
//             x: '50vw',
//             opacity: 0,
//         })
//         idx4Even && tl.set([idx4Even.querySelector('.idx-4__bg'), idx4Even.querySelector('.idx-4-content')], {
//                 x: '-50vw',
//                 opacity: 0,
//             })

//         tl.set([$('.idx-5-ls .item'), $('.idx-5-all')], {
//             opacity: 0,
//             y: '20vh'
//         })


//         tl.set([document.querySelector('.idx-6-content .title'), document.querySelector('.idx-6-content .desc'),
//         document.querySelector('.idx-7__title'), document.querySelector('.idx-7__desc')], {
//             x: -100,
//             opacity: 0,
//         }).set([$('.idx-6-slider .item-logo'),
//         $(".idx-7-ls>li .name"), $('.idx-7-ls>li .text')], {
//             y: 100,
//             opacity: 0
//         })

//         tl.set([$(".idx-7-ls>li")], {
//             scale: 2,
//             opacity: 0
//         })
//     }
//     else if(document.getElementById('aboutPage')){
//         tl.set([document.querySelector('.ab-1__title'),
//         document.querySelector('.ab-1-wrapper'),
//         document.querySelector('.ab-3__title'),
//         document.querySelector('.ab-3-desc'),
//         document.querySelector('.ab-4__title'),
//         document.querySelector('.ab-4__desc'),
//         document.querySelectorAll('.ab-4-wrapper .partner-ls'),
//         document.querySelector('.ab-5__title'),
//         document.querySelector('.ab-5__desc')], {
//             y: 100,
//             opacity: 0
//         })

//         tl.set([document.querySelectorAll('.ab-2-wrapper .item:nth-of-type(2n + 1) .item-content'),
//         document.querySelectorAll('.ab-2-wrapper .item:nth-of-type(2n) .item__thumbnail')], {
//             x: -100,
//             opacity: 0
//         }).set([document.querySelectorAll('.ab-2-wrapper .item:nth-of-type(2n) .item-content'),
//         document.querySelectorAll('.ab-2-wrapper .item:nth-of-type(2n + 1) .item__thumbnail')], {
//             x: 100,
//             opacity: 0
//         })
//         tl.set(document.querySelector('.ab-3 .timeline__bg img'), {
//             scale: 2,
//         })
//         .set([document.querySelectorAll('.ab-3-timeline .timeline-ls .item:nth-of-type(2n + 1)')], {
//             y: -100,
//             opacity: 0,
//         }).set([document.querySelectorAll('.ab-3-timeline .timeline-ls .item:nth-of-type(2n)')], {
//             y: 100,
//             opacity: 0,
//         }).set(document.querySelectorAll('.ab-5-wrapper .opt'), {
//             scale: 0,
//             opacity: 0
//         }).set(document.querySelectorAll('.ab-5-wrapper .wrapper-item .item-slide'), {
//             scale: 0,
//             opacity: 0
//         })
//     }

//     else if (document.getElementById('productsPage')){
//         tl.set([document.querySelector('.pro-1__title')], {
//             y: 100,
//             opacity: 0
//         }).set(document.querySelectorAll('.pro-1 .wrapper-ls .item'), {
//             y: 100,
//             opacity: 0
//         })
//     }
//     else if (document.getElementById('newsPage')){
//         tl.set([document.querySelector('.news-1__title')], {
//             y: 100,
//             opacity: 0
//         }).set(document.querySelectorAll('.news-1-wrapper .wrapper-ls .item .item-content>*'), {
//             y: 100,
//             opacity: 0
//         }).set(document.querySelectorAll('.news-1-wrapper .wrapper-ls .item .item-thumbnail'), {
//             x: 100,
//             opacity: 0
//         })
//     }
    
//     if (document.querySelector('.infor-template')){
//         let title = document.querySelector('.infor-template__title');
//         let desc = document.querySelector('.infor-template__desc');

//         let wrapper = document.querySelector('.infor-template-wrapper');
//         let nameWrapper = wrapper?.querySelectorAll('.name');
//         let descWrapper = wrapper?.querySelectorAll('.desc>*');
//         let moreWrapper = wrapper?.querySelectorAll('.more');
//         let thumbWrapper = wrapper?.querySelectorAll('.item__thumbnail');
//         let thumbVideoWrapper = wrapper?.querySelectorAll('.item__thumbnail--video');

//         title && tl.set(title, {
//             y: 100,
//             opacity: 0
//         })
//         desc && tl.set(desc, {
//             y: 100,
//             opacity: 0
//         })
        
//         nameWrapper.length > 0 && tl.set(nameWrapper, {
//             y: 100,
//             opacity: 0
//         })
//         descWrapper.length > 0 && tl.set(descWrapper, {
//             y: 100,
//             opacity: 0
//         })
//         moreWrapper.length > 0 && tl.set(moreWrapper, {
//             y: 100,
//             opacity: 0
//         })
//         thumbWrapper.length > 1 && tl.set(document.querySelectorAll('.infor-template-wrapper .item:nth-of-type(2n) .item__thumbnail'), {
//             x: 100,
//             opacity: 0
//         })
//         thumbWrapper.length > 0 && tl.set(document.querySelectorAll('.infor-template-wrapper .item:nth-of-type(2n + 1) .item__thumbnail'), {
//             x: -100,
//             opacity: 0
//         })
//         thumbVideoWrapper.length > 1 && tl.set(document.querySelectorAll('.infor-template-wrapper .item:nth-of-type(2n) .item__thumbnail--video'), {
//             x: 100,
//             opacity: 0
//         })
//         thumbVideoWrapper.length > 0 && tl.set(document.querySelectorAll('.infor-template-wrapper .item:nth-of-type(2n + 1) .item__thumbnail--video'), {
//             x: -100,
//             opacity: 0
//         })

//     }

//     document.querySelector('.breadcrumbs>ul>li') && tl.set(document.querySelectorAll('.breadcrumbs>ul>li'), {
//         x: 100,
//         opacity: 0
//     })
//     document.querySelector('.navbar') && tl.set(document.querySelectorAll('.navbar'), {
//         x: -100,
//         opacity: 0
//     })
//     document.querySelector('.pagination') && tl.set(document.querySelectorAll('.pagination'), {
//         y: 100,
//         opacity: 0
//     })

//     initSectionContact();
//     initFooter();
// }

// function initHeader() {
//     let header = document.querySelector('.header');
//     if (!header) {
//         return false;
//     }
//     tl.set([header.querySelector('.logo'),
//     header.querySelector('.header-navbar'),
//     header.querySelector('.languages')], {
//         opacity: 0,
//         scale: 0,
//     }).set(header.querySelector('.logo'), {
//         x: -100,
//     }).set(header.querySelector('.languages'), {
//         x: 100,
//     })
// }

// function initSectionContact() {
//     let element = document.querySelector('.section-contact');
//     if (element) {
//         element.querySelector('.content-name') && tl.set(element.querySelector('.content-name'), {
//             x: -100,
//             opacity: 0,
//         })
//         element.querySelector('.content-desc') && tl.set(element.querySelector('.content-desc'), {
//             x: -100,
//             opacity: 0,
//         })
//         element.querySelectorAll('.content-footer .item') && tl.set(element.querySelectorAll('.content-footer .item'), {
//             x: -100,
//             opacity: 0,
//         })
//         element.querySelector('.section-contact-form') && tl.set(element.querySelector('.section-contact-form'), {
//             y: 100,
//             opacity: 0
//         })
//     }
// }

// function initFooter() {
//     let element = document.querySelector('.footer');
//     if (element) {
//         element.querySelector('.footer-top .logo') && tl.set([element.querySelector('.footer-top .logo')], {
//             x: 100,
//             opacity: 0
//         })
//         element.querySelectorAll('.footer-navbar>ul>li').length > 0 && tl.set(element.querySelectorAll('.footer-navbar>ul>li'), {
//             y: -40,
//             opacity: 0,
//         })
//         element.querySelectorAll('.footer-information .item').length > 0 && tl.set(element.querySelectorAll('.footer-information .item'), {
//             x: 120,
//             opacity: 0
//         })
//         element.querySelector('.footer-copyright') && tl.set([element.querySelector('.footer-copyright')], {
//             scale: 0
//         })
//     }
// }