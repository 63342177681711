declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const pagination:any;
declare const Panzoom:any;


export const products = function () {
    let elem = document.getElementById('productsPage');
    if (elem){
        initPage()
    }
    
    else if (document.getElementById('productsDetailPage')){
        handleDetail();
    }
};


function initPage(){
    
    return new window.pagination({
        selector: '.pro-1 .pagination',
        items: '.pro-1 .wrapper-ls .item',
        scroll: {
            id: 'scrollProducts'
        },
        page: 6,
    })
}

function handleDetail(){
    let sw = detailSwiper();
    const zoomImg = $('#zoom_01');
    const objZoom = {
        easing: true,
        scrollZoom: true,
        lensSize: 120,
        zoomType: 'lens',
    }

    sw.on('slideChange', function (e:any) {
        let idxActive = e.activeIndex;
        let target = e.slides[idxActive];
        let img = <HTMLElement> target.querySelector('img');

        thumbMain.setAttribute('src', img.getAttribute('src'));
        // zoomImg.ezPlus(objZoom);
    });

    let thumbMain = document.querySelector('.pd-1 .thumbnail-main img');
    let lsItem = document.querySelectorAll('.pd-1 .item-thumbnail');

    if (thumbMain && lsItem[sw.activeIndex]){
        let target = lsItem[sw.activeIndex];
        let img = <HTMLElement> target.querySelector('img');
    
        thumbMain.setAttribute('src', img.getAttribute('src'));

        // zoomImg.ezPlus(objZoom);
    }

    

    lsItem.forEach(function(item, idx){
        item.addEventListener('click', function(e){
            e.preventDefault();
            sw.slideTo(idx);
        })
    })

    let specifications = document.querySelectorAll('.pd-1 .content .item-specifications>*');
    specifications.forEach(function(itemSpec){
        let inp = <HTMLInputElement>itemSpec.querySelector('input');
        itemSpec.addEventListener('click', function(e){
            e.preventDefault();

            let target = <HTMLElement> e.target;
            let valActive = target.getAttribute('activeSlide');
            
            lsItem.forEach(function(itemThumbnail, idx){
                let thumbActive = itemThumbnail.getAttribute('slideName');

                if (valActive && thumbActive && valActive === thumbActive){
                    sw.slideTo(idx);
                }
            })

            if (itemSpec.classList.contains('is-actived')){
                inp ? inp.checked = false : "";
                itemSpec.classList.remove('is-actived');
            }
            else{
                window.delHandle(specifications, 'is-actived');
                inp ? inp.checked = true : "";
                itemSpec.classList.add('is-actived');
            }
        })
    })

    let materials = document.querySelectorAll('.pd-1 .content .item-materials>*');
    materials.forEach(function(itemMaterial){
        itemMaterial.addEventListener('click', function(e){
            
            e.preventDefault();

            let target = <HTMLElement> e.target;
            let valActive = target.getAttribute('activeSlide');
            
            lsItem.forEach(function(itemThumbnail, idx){
                let thumbActive = itemThumbnail.getAttribute('slideName');

                if (valActive && thumbActive && valActive === thumbActive){
                    sw.slideTo(idx);
                }
            })

            if (itemMaterial.classList.contains('is-actived')){
                itemMaterial.classList.remove('is-actived');
            }
            else{
                window.delHandle(materials, 'is-actived');
                itemMaterial.classList.add('is-actived');
            }
        })
    })
}

function detailSwiper(){
    let el = document.querySelector('.pd-1 .thumbnail-slide .swiper');
    let next = el.querySelector('.swiper-button-next');
    let prev = el.querySelector('.swiper-button-prev');

    next.innerHTML = `<svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.82 0L0 2.82L9.16 12L0 21.18L2.82 24L14.82 12L2.82 0Z" fill="white"/>
                    </svg>`;
    prev.innerHTML = `<svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.9998 24L14.8198 21.18L5.65983 12L14.8198 2.82L11.9998 -2.46532e-07L-0.000174427 12L11.9998 24Z" fill="white"/>
                    </svg>`;
                    
    return new Swiper(el, {
        slidesPerView: 3,
        spaceBetween: 16,
        centeredSlides: true,
        initialSlide: 0,
        
        navigation: {
            nextEl: next,
            prevEl: prev,
        },

        

        // loop: true,

        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
        
        // autoplay: {
        //     delay: 1800,
        //     disableOnInteraction:true,
        // }
    })
}