declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;

export const index = function () {
    let elem = document.getElementById('indexPage');
    if (elem){
        introInit();

        swapBg4(elem);
        handle6(elem);
    }
};


function introInit(){
    const introSkip = document.querySelector('.index-intro-skip .scroll-prompt');
    const idxIntro = <HTMLElement> document.querySelector('.idx-intro');
  
    let header = <HTMLElement> document.querySelector('header');
    document.querySelector('body').classList.add('index-page');
  
    let timeout:any;
    if (idxIntro && introSkip && header){
        // header.classList.add('scroll');
      
        window.onbeforeunload = function () {
          window.scrollTo(0, 0);
        }
        
        introSkip && introSkip.addEventListener('click', function(el){
            el.preventDefault();
            clearTimeout(timeout);
            
            const promise = new Promise((res, rej) => {
                let timeoutInner:any;
                timeout = setTimeout(function(){
                    document.body.classList.remove('index-page');
                    
                    idxIntro.style.transform = "translateY(-100%)";
                    idxIntro.style.transition = "1s transform linear";
      
                    clearTimeout(timeoutInner);
                    timeoutInner = setTimeout(() => {
                        res(true);
                    }, 1000);
                }, 300)
            })
            promise.then(function(value){
                idxIntro.remove();
            })
        })
    }
}
  

function swapBg4(elem:HTMLElement){
    const wrapper = elem.querySelectorAll('.idx-4-wrapper');
    wrapper.forEach(function(itemWrapper){
        const sections = itemWrapper.querySelectorAll('.idx-4--section');
        if(sections.length > 2){
            console.error("Can't change!");
        }else{
            const valImg1 = sections[0]?.querySelector('.idx-4__bg img');
            const valImg2 = sections[1]?.querySelector('.idx-4__bg img');
        
            if (valImg1 && valImg2){
                const val1 = valImg1.getAttribute('src');
                const val2 = valImg2.getAttribute('src');
                
                valImg1.setAttribute('src', val2);
                valImg2.setAttribute('src', val1);
            }
        }
    })
}

function sw6(elem:HTMLElement){
    let sw = new Swiper(elem, {
        spaceBetween: 30,
        scrollbar: {
            el: ".swiper-scrollbar",
            hide: true,
        },

        slidesPerView: "auto",
        slidesPerGroup: 1,
        speed: 800,
        autoplay:{
            delay: 3000,
            disableOnInteraction: false,
            stopOnLastSlide: true,
        }
    })
    sw.autoplay.stop();
    return sw;
}


function handle6(elem:HTMLElement){
    let item = <HTMLElement> elem.querySelector('.idx-6-slider .swiper');
    let sw = sw6(item);

    let y = item.getBoundingClientRect().y;
    if (y <= window.innerHeight - item.clientHeight){
        item.classList.add('saw');
        sw.autoplay.start();
    }else{
        item.classList.remove('saw');
        sw.autoplay.stop();
    }
    
    document.addEventListener('scroll', function(e){
        let y = item.getBoundingClientRect().y;
        if (y <= window.innerHeight - item.clientHeight){
            item.classList.add('saw');
            sw.autoplay.start();
        }else{
            item.classList.remove('saw');
            sw.autoplay.stop();
        }
    })
}