declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;

export const about = function () {
    let elem = document.getElementById('aboutPage');
    if (elem){
        handle3(elem);
        initSw4(elem);
        handle5(elem);
    }
};

function handle3(elem:HTMLElement){
    let item = <HTMLElement> elem.querySelector('.ab-3 .swiper');
    let sw = initSw3(item);
    
    // let len = item.querySelectorAll('.swiper-slide').length;
    let dashline = elem.querySelector('.ab-3 .dashline');

    document.addEventListener('scroll', function(e){
        let y = item.getBoundingClientRect().y;
        if (y <= window.innerHeight - item.clientHeight){
            dashline.classList.add('actived');
            sw.autoplay.start();
        }else{
            dashline.classList.remove('actived');
            sw.autoplay.stop();
        }
    })
}

function initSw3(elem:HTMLElement){
    let sw = new Swiper(elem, {
        slidesPerView: "auto",
        spaceBetween: 0,
        slidesPerGroup: 1,
        speed: 800,
        autoplay:{
            delay: 3000,
            disableOnInteraction: false,
            stopOnLastSlide: true,
        }
        // autoplay: {
        //     delay: 0,
        //     disableOnInteraction: false,
        //     reverseDirection: true,
        // },
    })
    sw.autoplay.stop();
    return sw;
}

function initSw4(elem:HTMLElement){
    let ls = elem.querySelectorAll('.ab-4 .partner-ls');
    ls.forEach(function(item:HTMLElement){
        let speed = item.getAttribute('dt-speed');
        let sw = swPartner(item, parseInt(speed));
        let checked = false;

        document.addEventListener('scroll', function(e){
            let y = item.getBoundingClientRect().y;
            if (y < window.innerHeight * 3 / 4 - item.clientHeight){
                if (!checked){
                    sw.autoplay.start();
                    checked = true;
                }
            }
            else{
                if (checked === true){
                    sw.autoplay.stop();
                    checked = false;
                }
            }
        })
    })
}

function swPartner(item:HTMLElement, speed:number, delay:number = 0){
    let sw = new Swiper(item, {
        slidesPerView: 4,
        spaceBetween: 24,
        
        speed: typeof(speed) === "number" ? speed : 3100,
        loop: true,
        freeMode: {
            enabled: true,
            sticky: true,
        },

        autoplay:{
            delay: 0,
            disableOnInteraction: false,
        },
        breakpoints: {
            1024: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
        }
    })
    sw.autoplay.stop();
    
    return sw;
}

function handle5(elem:HTMLElement){
    let wrappers = document.querySelectorAll('.ab-5-wrapper .wrapper-item');
    let opts = elem.querySelectorAll('.ab-5-wrapper .opt-item');
    let swFlag:any;
    var gap = 30;

    if (window.innerWidth < 1024){
        gap = 13;
    }
    else{
        gap = 30;
    }

    window.addEventListener('resize', function(){
        if (window.innerWidth < 1024){
            gap = 13;
        }
        else{
            gap = 30;
        }
    })

    wrappers.forEach(function(itemw:HTMLElement, idx){
        if(itemw.classList.contains('showed')){
            swFlag = createInit5(itemw, gap);
        }

        opts[idx].addEventListener('click', function(e){
            e.preventDefault();
            window.delHandle(opts, 'active');
            this.classList.add('active');

            window.delHandle(wrappers, 'showed');
            itemw.classList.add('showed');

            if(swFlag){
                swFlag.destroy();
                swFlag = createInit5(itemw, gap);
            }
        })

        // let slides = itemsw.querySelectorAll('.swiper-slide');
        // let htmls = document.createElement('div');
        // htmls.classList.add('swiper');
        // let swwrapper = document.createElement('div');
        // swwrapper.classList.add('swiper-wrapper');
        
        // let i = slides.length 
    })
}

function createInit5(itemw:HTMLElement, spaceBetween:number = 30){
    let swItem = <HTMLElement> itemw.querySelector('.swiper');
    let sw = initSw5(swItem, itemw.querySelector('.swiper-pagination'), spaceBetween);
    return sw;
}

function initSw5(swItem:HTMLElement, epagination: HTMLElement, spaceBetween:number){
    let sw = new Swiper(swItem, {
        slidesPerView: 2,
        grid: {
          rows: 2,
          fill: 'row'
        },
        spaceBetween: spaceBetween,
        pagination: {
          el: epagination,
          clickable: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 3,
            },
        }
    })

    let hItem = swItem.querySelector('.slide');
    if(hItem)
    {
        swItem.style.height = (hItem.clientHeight * 2) + spaceBetween + "px";
    }   

    return sw;
}
