declare const gsap: any;
declare const ScrollTrigger: any;
declare const $:any;
gsap.registerPlugin(ScrollTrigger);

const durationDefault = 0.4725;

function initObjST(element: any, obj:any = {}){
    if (element){
        return {
            scrollTrigger: {
                trigger: element,
                start: "top 90%",
                end: "center 70%",
                toggleClass: "gsap-actived",
                ...obj
            }
        }
    }
    else{
        return {}
    }
}

function gsapToElement(element:any, outObj:any = {}, inObj:any = {}){
    if (element){
        let initSt = initObjST(element, inObj);
        let objEffect = {
            ...outObj,
            ...initSt,
        }
    
        gsap.to(element, objEffect)
    }
    
}

export const gsapScroll = function () {
    initHeader();

    effectDefault();
    homepage();
    aboutPage();
    productsPage();
    newsPage();
    contactPage();

    initSectionContact();
    initFooter();

    document.querySelectorAll('.breadcrumbs>ul>li').length > 0 && gsapToElement(document.querySelectorAll('.breadcrumbs>ul>li'), {
        x: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 0.6
    });

    if (document.querySelector('.infor-template')){
        document.querySelector('.infor-template__title') && gsapToElement(document.querySelector('.infor-template__title'), {
            y: 0,
            opacity: 1,
            duration: 1
        })        
        document.querySelector('.infor-template__desc') && gsapToElement(document.querySelector('.infor-template__desc'), {
            y: 0,
            opacity: 1,
            duration: 1
        })

        let items = document.querySelectorAll('.infor-template-wrapper .item');
        items.forEach(function(item){
            let tl = gsap.timeline(initObjST(item));
            if(item.querySelector('.item__thumbnail')){
                tl.to(item.querySelector('.item__thumbnail'), {
                    x: 0,
                    opacity: 1,
                    duration: 1,
                })
            }
            if(item.querySelector('.item__thumbnail--video')){
                tl.to(item.querySelector('.item__thumbnail--video'), {
                    x: 0,
                    opacity: 1,
                    duration: 1,
                })
            }
            if(item.querySelector('.item-content .name')){
                tl.to(item.querySelector('.item-content .name'), {
                    y: 0,
                    opacity: 1,
                    duration: .3,
                }, "<")
            }
            if(item.querySelector('.item-content .desc>*')){
                tl.to(item.querySelectorAll('.item-content .desc>*'), {
                    y: 0,
                    opacity: 1,
                    duration: .3,
                    stagger: .1
                })
            }
            if(item.querySelector('.item-content .more')){
                tl.to(item.querySelector('.item-content .more'), {
                    y: 0,
                    opacity: 1,
                    duration: .3,
                })
            }
        })
    }

    document.querySelector('.navbar') && gsapToElement(document.querySelectorAll('.navbar'), {
        x: 0,
        opacity: 1,
        duration: 1
    });
    
    document.querySelector('.pagination') && gsapToElement(document.querySelectorAll('.pagination'), {
        y: 0,
        opacity: 1,
        duration: 1
    });

    return true;
}

function effectDefault(){
    document.querySelector('.effect-top') && gsapToElement(document.querySelectorAll('.effect-top'), {
        y: 0,
        opacity: 1,
        duration: 1
    });
    document.querySelector('.effect-top-stagger>*') && gsapToElement(document.querySelectorAll('.effect-top-stagger>*'), {
        y: 0,
        opacity: 1,
        duration: 1,
        stagger: 0.2
    });
    document.querySelector('.effect-bottom') && gsapToElement(document.querySelectorAll('.effect-bottom'), {
        y: 0,
        opacity: 1,
        duration: 1
    });
    document.querySelector('.effect-bottom-stagger>*') && gsapToElement(document.querySelectorAll('.effect-bottom-stagger>*'), {
        y: 0,
        opacity: 1,
        duration: 1,
        stagger: 0.2
    });
    document.querySelector('.effect-left') && gsapToElement(document.querySelectorAll('.effect-left'), {
        x: 0,
        opacity: 1,
        duration: 1
    });
    document.querySelector('.effect-left-stagger>*') && gsapToElement(document.querySelectorAll('.effect-left-stagger>*'), {
        x: 0,
        opacity: 1,
        duration: 1,
        stagger: 0.2
    });
    document.querySelector('.effect-right') && gsapToElement(document.querySelectorAll('.effect-right'), {
        x: 0,
        opacity: 1,
        duration: 1
    });
    document.querySelector('.effect-right-stagger>*') && gsapToElement(document.querySelectorAll('.effect-right-stagger>*'), {
        x: 0,
        opacity: 1,
        duration: 1,
        stagger: 0.2
    });
}

function contactPage(){
    if(!document.getElementById('contactPage')){
        return false;
    }

    gsapToElement(document.querySelector('.ct-1__title'), {
        y: 0,
        opacity: 1,
        duration: durationDefault,
    });

    let items = document.querySelectorAll('.ct-1-wrapper');
    items.forEach(function(item){
        let tl = gsap.timeline(initObjST(item));
        item.querySelector('.maps') && tl.to(item.querySelector('.maps'), {
            x: 0,
            opacity: 1,
            duration: durationDefault,
            ease: "none"
        })

        item.querySelector('.content .name') && tl.to(item.querySelector('.content .name'), {
            x: 0,
            opacity: 1,
            duration: durationDefault
        })
        item.querySelector('.content .addr') && tl.to(item.querySelector('.content .addr'), {
            x: 0,
            opacity: 1,
            duration: durationDefault
        }, "<")

        item.querySelectorAll('.content-infor .item .infor>*').length > 0 && tl.to(item.querySelectorAll('.content-infor .item .infor>*'), {
            y: 0,
            opacity: 1,
            stagger: 0.1,
            duration: 0.4,
            ease: "none"
        })
        item.querySelectorAll('.content-infor .item .icon').length > 0 && tl.to(item.querySelectorAll('.content-infor .item .icon'), {
            scale: 1,
            opacity: 1,
            stagger: 0.1,
            duration: 0.4,
            ease: "none"
        }, "<")
    })

    gsapToElement(document.querySelector('.ct-2-box'), {
        y: 0,
        opacity: 1,
        duration: durationDefault,
    });
    gsapToElement(document.querySelector('.ct-2-box .ct-2__title'), {
        y: 0,
        opacity: 1,
        duration: durationDefault,
    });
    gsapToElement(document.querySelectorAll('.ct-2-box .group-nav'), {
        y: 0,
        opacity: 1,
        duration: durationDefault,
    });
    gsapToElement(document.querySelectorAll('.ct-2-box .group-form'), {
        y: 0,
        opacity: 1,
        duration: durationDefault,
    });

    gsapToElement(document.querySelector('.ct-3__title'), {
        x: 0,
        opacity: 1,
        duration: 1
    });

    gsapToElement(document.querySelectorAll('.ct-3-wrapper .question-ls .item'), {
        y: 0,
        opacity: 1,
        duration: 1
    });

}
function newsPage(){
    if(!document.getElementById('newsPage')){
        return false;
    }
    gsapToElement(document.querySelector('.news-1__title'), {
        y: 0,
        opacity: 1
    });

    let items = document.querySelectorAll('.news-1-wrapper .wrapper-ls .item');
    items.forEach(function(item){
        let tl = gsap.timeline(initObjST(item));
        item.querySelectorAll('.item-content>*').length > 0 && tl.to(item.querySelectorAll('.item-content>*'), {
            y: 0,
            opacity: 1,
            stagger: 0.2,
            duration: durationDefault,
            ease: "none"
        })
        item.querySelector('.item-thumbnail') && tl.to(item.querySelector('.item-thumbnail'), {
            x: 0,
            opacity: 1,
            duration: durationDefault,
            ease: "none"
        }, "<")
    })
}

function productsPage(){
    if(!document.getElementById('productsPage')){
        return false;
    }
    const outObjY= {
        opacity: 1,
        y: 0,
        duration: durationDefault,
    }
    gsapToElement(document.querySelector('.pro-1__title'), outObjY);
    gsapToElement(document.querySelectorAll('.pro-1 .wrapper-ls .item'), {
        opacity: 1,
        y: 0,
        stagger: 0.3,
        duration: durationDefault,
    });
}

function aboutPage(){
    if(!document.getElementById('aboutPage')){
        return false;
    }
    const outObjX= {
        opacity: 1,
        x: 0,
        stagger: .1,
        duration: durationDefault,
    }
    const outObjY= {
        opacity: 1,
        y: 0,
        stagger: .1,
        duration: durationDefault,
    }

    const scaleDefault = {
        scale: 1,
        opacity: 1,
        duration: durationDefault,
    }

    gsapToElement(document.querySelector('.ab-1__title'), outObjY);
    gsapToElement(document.querySelector('.ab-1-wrapper'), outObjY);
    
    gsapToElement(document.querySelectorAll('.ab-2-wrapper .item-content'), outObjX);
    gsapToElement(document.querySelectorAll('.ab-2-wrapper .item__thumbnail'), outObjX);


    gsapToElement(document.querySelector('.ab-3__title'), outObjY);
    gsapToElement(document.querySelector('.ab-3-desc'), outObjY);
    gsapToElement(document.querySelector('.ab-3 .timeline__bg img'), scaleDefault);
    
    gsapToElement(document.querySelectorAll('.ab-3-timeline .timeline-ls .item'), outObjY);

    gsapToElement(document.querySelector('.ab-4__title'), outObjY);
    gsapToElement(document.querySelector('.ab-4__desc'), outObjY);
    
    gsapToElement(document.querySelectorAll('.ab-4-wrapper .partner-ls'), outObjY);
    
    gsapToElement(document.querySelector('.ab-5__title'), outObjY);
    gsapToElement(document.querySelector('.ab-5__desc'), outObjY);
    gsapToElement(document.querySelectorAll('.ab-5-wrapper .opt'), scaleDefault);
    gsapToElement(document.querySelectorAll('.ab-5-wrapper .wrapper-item .item-slide'), scaleDefault);

    return true;
}

function initHeader(){
    let header = document.querySelector('.header');
    if (header){
        const tl = gsap.timeline();
    
        header.querySelector('.logo') && tl.to(header.querySelector('.logo'), {
            x: 0,
            opacity: 1,
            duration: 0.4,
            ease: "none"
        })
        header.querySelector('.header-navbar') && tl.to(header.querySelectorAll('.header-navbar>ul>li'), {
            y: 0,
            opacity: 1,
            stagger: 0.1,
            duration: 0.4
        }, "<")
        header.querySelector('.languages') && tl.to(header.querySelector('.languages'), {
            x: 0,
            opacity: 1,
            duration: 0.4,
            ease: "none"
        }, "<")
    
        return true;
    }
    return false;
}

function homepage(){
    if (document.getElementById('indexPage')){
        initIndex1();
        initCube();

        initIndex2();
        initIndex3();
        initIndex4();
        initIndex5();
        initIndex6();
        initIndex7();

        return true;
    }
}

function initIndex1(){
    let elem = document.querySelector('.idx-1');
    if (!elem){
        return false;
    }
    const tl = gsap.timeline(initObjST(elem));

    let objEffect = {
        y: 0,
        opacity: 1,
        duration: 1
    };

    elem.querySelector(".idx-1__title") && tl.to(elem.querySelector(".idx-1__title"), objEffect)
    elem.querySelector(".idx-1__desc") && tl.to(elem.querySelector(".idx-1__desc"), objEffect, "-=0.3")
    elem.querySelector(".idx-1-more") && tl.to(elem.querySelector(".idx-1-more"), objEffect, "-=0.3")
}
function initIndex2(){
    let elem = document.querySelector('.idx-2');
    if (!elem){
        return false;
    }

    // const outObjCube = {
    //     scale: 1,
    //     y: 100,
    //     duration: 2,
    //     delay: 1,
    // }
    // gsapToElement(elem.querySelector('.idx-2-cube'), outObjCube);

    const outObjQuote = {
        opacity: 1,
        x: 0,
        duration: durationDefault,
    }

    gsapToElement(elem.querySelector('.quote'), outObjQuote);
}
function initIndex3(){
    let elem = document.querySelector('.idx-3');
    if (!elem){
        return false;
    }

    const outObj = {
        scale: 1,
        duration: durationDefault,
    }
    gsapToElement(elem.querySelector('.idx-3__thumbnail'), outObj, {scrub: true});

    elem.querySelector('.idx-3-wrapper') && gsap.to(elem.querySelector('.idx-3-wrapper'), {
        opacity: 1,
        y: 0,
        duration: .6,
        scrollTrigger: {
            trigger: elem,
            start: "top 80%",
            end: "bottom 40%",
            toggleClass: "gsap-actived",
        }
    })
}
function initIndex4(){
    let elem = document.querySelector('.idx-4');
    if (!elem){
        return false;
    }

    const outObj = {
        x: 0,
        opacity: 1,
        duration: durationDefault,
    }

    let bgs = elem.querySelectorAll('.idx-4__bg');
    bgs.forEach(function(itemBg:any){
        gsapToElement(itemBg, outObj);
    })
    let cnts = elem.querySelectorAll('.idx-4-content');
    cnts.forEach(function(itemCnt){
        gsapToElement(itemCnt, outObj);
    })
}
function initIndex5(){
    let elem = document.querySelector('.idx-5');
    if (!elem){
        return false;
    }

    const outObj = {
        y: 0,
        opacity: 1,
        duration: durationDefault,
    }

    let bgs = elem.querySelectorAll('.idx-5-ls .item');
    bgs.forEach(function(itemBg){
        gsapToElement(itemBg, outObj);
    })
    
    gsapToElement(document.querySelector('.idx-5-all'), outObj);

}

function initIndex6(){
    let elem = document.querySelector('.idx-6');
    if (!elem){
        return false;
    }

    const outObjContent = {
        opacity: 1,
        x: 0,
        stagger: 0.2,
        duration: durationDefault
    }

    const outObjSlider = {
        opacity: 1,
        y: 0,
        stagger: 0.1,
        duration: durationDefault
    }

    gsapToElement($('.idx-6-content>*'), outObjContent);
    gsapToElement($('.idx-6-slider .item-logo'), outObjSlider);
}

function initIndex7(){
    let elem = document.querySelector('.idx-7');
    if (!elem){
        return false;
    }

    const outObjContent = {
        opacity: 1,
        x: 0,
        duration: durationDefault,
    }

    gsapToElement($('.idx-7__title'), outObjContent);
    gsapToElement($('.idx-7__desc'), outObjContent);

    let items = elem.querySelectorAll('.idx-7-ls>li');
    items.forEach(function(item:any){
        let tl = gsap.timeline(initObjST(item));
        item && tl.to(item, {
            scale: 1,
            opacity: 1,
            duration: 1.4
        })
        item.querySelector('.name') && tl.to(item.querySelector('.name'), {
            opacity: 1,
            x: 0,
            y: 0,
            duration: durationDefault,
        }, "-=.4")
        item.querySelector('.text') && tl.to(item.querySelector('.text'), {
            opacity: 1,
            y: 0,
            x: 0,
            duration: durationDefault,
        })
    })
}

function initSectionContact(){
    let elem = document.querySelector('.section-contact');
    if (!elem){
        return false;
    }

    const outObjContent = {
        opacity: 1,
        x: 0,
        duration: durationDefault
    }
    const outObjForm = {
        opacity: 1,
        y: 0,
        duration: durationDefault
    }

    gsapToElement(elem.querySelector('.content-name'), outObjContent);
    gsapToElement(elem.querySelector('.content-desc'), outObjContent);
    
    gsapToElement(elem.querySelector('.section-contact-form'), outObjForm);
    gsapToElement(elem.querySelectorAll('.content-footer .item'), outObjContent);
}


function initCube(){
    if (document.getElementById('indexPage')){
        if (document.querySelector('.idx-2-cube')){
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: ".idx-2-cube",
                    start: "center center",
                    end: `bottom -${window.innerHeight}px`,
                    scrub: 4,
                    pin: true,
                    toggleActons: "restart     none    none        none",
                                // onEnter onLeave onEnterBack onLeaveBack
                                // play pause restart reset resume reverse complete none
                    // markers: true,
                    toggleClass: "gsap-actived",
                    // onUpdate: (self:any) => {
                    //     // console.log(self.progress);
                    // },

                    snap: {
                        snapTo: [0, 1/11, 2/11, 3/11, 4/11, 5/11, 6/11, 7/11, 8/11, 9/11, 10/11, 1],
                        duration: { min: 1, max: 2 },
                        delay: 0.3
                    },
                }
            })
            const box = document.querySelector('.idx-2-cube .box');

            const cube = box.querySelector('.cube');
            const valTime = 5;
            const content = <HTMLElement> box.querySelector('.content');

            if (cube && content){
                tl.to(cube, {
                    duration: 0,
                    onComplete: function(){
                        content.style.visibility = "hidden";
                    },
                    onReverseComplete: function(){
                        content.style.visibility = "hidden";
                    }
                })

                tl.to(cube, {
                    duration: 0.5,
                    onStart: function(){
                        content.style.visibility = "visible";
                    }
                })
                tl.to(cube, {
                    rotationY: 45,
                    duration: 0
                })


                // top
                const elementTop = <HTMLElement> content.querySelector('.content-item.top');
                if (elementTop){
                    elementTop.classList.add('pos-tc');

                    // 5s
                    tl.to(cube, {
                        rotationY: 0,
                        duration: valTime,
                    })

                    // 20s
                    timelineTop(tl, elementTop);

                    // cân bằng thời giản visible
                    tl.to(cube, {
                        duration: valTime + 0.5,
                    })
                }

                // -> top => 31s (lần 1 và 2) -> mỗi lần 15.5s
        

                // front
                const elementFront = <HTMLElement> content.querySelector('.content-item.front');
                if (elementFront){
                    elementFront.classList.add('pos-front');
                    tl.to(cube, {
                        rotationY: 0,
                        duration: valTime + 0.5,
                    })
                    // 20s
                    timelineFace(tl, elementFront);

                    tl.to(cube, {
                        duration: valTime + 0.5,
                    })
                }
        
                // right
                const elementRight = <HTMLElement> content.querySelector('.content-item.right');
                if (elementRight){
                    elementRight.classList.add('pos-right');
                    tl.to(cube, {
                        rotationY: -90,
                        duration: valTime + 0.5,

                    })
                    timelineFace(tl, elementRight);
                    tl.to(cube, {
                        duration: valTime + 0.5,
                    })
                }
        
                // back
                const elementBack = <HTMLElement> content.querySelector('.content-item.back');
                if (elementBack){
                    elementBack.classList.add('pos-back');
                    tl.to(cube, {
                        rotationY: -180,
                        duration: valTime + 0.5,

                    })
                    timelineFace(tl, elementBack);
                    tl.to(cube, {
                        duration: valTime + 0.5,
                    })
                }
        
        
                //left
                const elementLeft = <HTMLElement> content.querySelector('.content-item.left');
                if (elementLeft){
                    elementLeft.classList.add('pos-left');
                    tl.to(cube, {
                        scale: 1,
                        rotationY: -270,
                        duration: valTime + 0.5,
                    })
                    timelineFace(tl, elementLeft);
                    tl.to(cube, {
                        duration: valTime + 0.5,
                    })
                }
        
                // END
                tl.to(cube, {
                    scale: 1,
                    rotationY: -315,
                    duration: 15.5
                })
            }
        }

        // setInterval(function() {
        //     if (isScrolling) {
        //       // Lấy chiều cao của thẻ div và thanh cuộn
        //       const divHeight = scrollable.clientHeight;
        //       const scrollHeight = scrollable.scrollHeight;
          
        //       // Tính toán vị trí hiện tại của thanh cuộn
        //       const scrollTop = scrollable.scrollTop;
        //       const scrollPercent = (scrollTop + divHeight) / scrollHeight;
          
        //       // Nếu thanh cuộn đã cuộn đến cuối thẻ div
        //       if (scrollPercent === 1) {
        //         // Ngừng cuộn sau 10 lần cuộn
        //         if (count < 10) {
        //           count++;
        //         } else {
        //           isScrolling = false;
        //           count = 0;
        //         }
        //       }
        //     }
        // }, 100);

    }
}

function timelineTop(tl: any, element:HTMLElement){
    let cube = document.querySelector('.cube');
    let dCube = cube.clientWidth;

    let dLine1 = 0;
    let topSpace = 0;

    if (element.classList.contains('pos-tc')){
        element.classList.add('dot-bottom');
        if( window.innerWidth < 768){
            dLine1 = 80;
            topSpace = -120;
        }
        else if( window.innerWidth < 1024){
            dLine1 = 120;
            topSpace = -180;
        }
        else if( window.innerWidth < 1440){
            dLine1 = 120;
            topSpace = -180;
        }
        else{
            dLine1 = 114;
            topSpace = -180;
        }
    }
    else if (element.classList.contains('pos-bc')){
        element.classList.add('dot-top');

        if( window.innerWidth < 768){
            dLine1 = 80;
            topSpace = dCube + 60;
        }

        else if( window.innerWidth < 1024){
            dLine1 = 120;
            topSpace = dCube + 120;
        }
        else if( window.innerWidth < 1440){
            dLine1 = 120;
            topSpace = dCube + 120;
        }
        else{
            dLine1 = 114;
            topSpace = dCube + 114;
        }
    }

    window.addEventListener('resize', function(){
        if (element.classList.contains('pos-tc')){
            element.classList.add('dot-bottom');
            if( window.innerWidth < 768){
                dLine1 = 80;
                topSpace = -120;
            }
            else if( window.innerWidth < 1024){
                dLine1 = 120;
                topSpace = -180;
            }
            else if( window.innerWidth < 1440){
                dLine1 = 120;
                topSpace = -180;
            }
            else{
                dLine1 = 114;
                topSpace = -180;
            }
        }
        else if (element.classList.contains('pos-bc')){
            element.classList.add('dot-top');
    
            if( window.innerWidth < 768){
                dLine1 = 80;
                topSpace = dCube + 60;
            }
    
            else if( window.innerWidth < 1024){
                dLine1 = 120;
                topSpace = dCube + 120;
            }
            else if( window.innerWidth < 1440){
                dLine1 = 120;
                topSpace = dCube + 120;
            }
            else{
                dLine1 = 114;
                topSpace = dCube + 114;
            }
        }
    })

    const obj = {
        timeline: tl,
        element: element,
        valueSpace: topSpace,
        valueLine: dLine1
    }
    animationCenter(obj);
}

function timelineFace(tl:any, element:HTMLElement){
    let cube = document.querySelector('.cube');
    let dCube = cube.clientWidth;
    let wElement = element.clientWidth;
    let hElement = element.clientHeight;
    
    let pos = {
        top: 0,
        left: 0,
        x: 0,
        y: 0
    }
    const durLine1 = 1;
    const durLine2 = 2;

    let line1 = {width: 0, height: 0, duration: durLine1};
    let line2 = {
        width: 0, 
        height: 0,
        x: 0,
        y: 0,
        top: 0,
        left: 0,
        duration: durLine2,
    };
    element.classList.add('face');


    if (element.classList.contains('pos-front')){
        element.classList.add('dot-top');

        if( window.innerWidth < 768){
            pos = {
                top: dCube + 120,
                left: dCube / 2,
                x: - wElement / 2,
                y: hElement
            }

            line1.height = 120;
            line1.duration = durLine1 + durLine2; 

            line2.duration = 0;
        }
        else if( window.innerWidth < 1024){
            pos = {
                top: dCube + 60,
                left: dCube / 2,
                x: -wElement,
                y: hElement
            }

            line1.height = 140;
            
            line2.top = - line1.height - 10;

            line2.left = wElement / 2;
            line2.width = wElement / 2 + 20;
    
            line2.x = 0;
            line2.y = 0;
        }
        else if( window.innerWidth < 1440){
            pos = {
                top: dCube,
                left: -20,
                x: -wElement,
                y: hElement
            }

            line1.height = 80;

            line2.top = - line1.height - 10;
            line2.left = wElement / 2;
            line2.width = wElement / 2 + 20;
    
            line2.x = 0;
            line2.y = 0;
        }
        else{
            pos = {
                top: dCube,
                left: -20,
                x: -wElement,
                y: hElement
            }

            line1.height = 80;

            line2.top = - line1.height - 10;
            line2.left = wElement / 2;
            line2.width = wElement / 2 + 20;

            line2.x = 0;
            line2.y = 0;
        }
    }
    else if (element.classList.contains('pos-right')){
        element.classList.add('dot-bottom');
        if( window.innerWidth < 768){
            pos = {
                top: -123,
                left: dCube / 2,
                x: - wElement / 2,
                y: -hElement
            }

            line1.height = dCube / 2 + 123;
            line2.width = wElement / 2;

            line2.top = hElement + line1.height + 10;
            line2.left = 5;
    
            line2.x = 0;
            line2.y = 0;
        }
        else if( window.innerWidth < 1024){
            pos = {
                top: -dCube / 2,
                left: dCube / 2,
                x: 0,
                y: -hElement
            }

            line1.height = dCube / 2 +  100;
            line2.width = wElement / 2;
    
            line2.top = hElement + line1.height + 10;
            line2.left = wElement / 2;
    
            line2.x = 0;
            line2.y = 0;
        }
        else if( window.innerWidth < 1440){
            pos = {
                top: 0,
                left: dCube + 20,
                x: 0,
                y: 0
            }

            line1.height = 80;
            line2.width = wElement / 2 + 20;
    
            line2.top = hElement + line1.height + 10;
            line2.left = wElement / 2;
    
            line2.x = 0;
            line2.y = 0;
        }
        else{
            pos = {
                top: 0,
                left: dCube + 20,
                x: 0,
                y: 0
            }

            line1.height = 80;
            line2.width = wElement / 2 + 20;
    
            line2.top = hElement + line1.height + 10;
            line2.left = wElement / 2;
    
            line2.x = 0;
            line2.y = 0;
        }


    }
    else if (element.classList.contains('pos-back')){
        element.classList.add('dot-bottom');
        if( window.innerWidth < 768){
            element.classList.remove('dot-bottom');
            element.classList.add('dot-top');
            pos = {
                top: dCube + 120,
                left: dCube / 2,
                x: - wElement / 2,
                y: hElement
            }

            line1.height = 120;
            line1.duration = durLine1 + durLine2;

            line2.duration = 0;
        }
        else if( window.innerWidth < 1024){
            pos = {
                top: -dCube / 2,
                left: dCube / 2,
                x: -wElement,
                y: -hElement
            }
    
            line1.height = dCube / 2 + 100;
            line2.width = wElement / 2;
    
            line2.top = hElement + line1.height + 10;
            line2.left = wElement / 2;
    
            line2.x = 0;
            line2.y = 0;
        }
        else if( window.innerWidth < 1440){
            pos = {
                top: dCube / 2 - 30,
                left: -20,
                x: -wElement,
                y: -hElement
            }
    
            line1.height = 80;
            line2.width = wElement / 2 + 20;
    
            line2.top = hElement + line1.height + 10;
            line2.left = wElement / 2;
    
            line2.x = 0;
            line2.y = 0;
        }
        else{
            pos = {
                top: dCube / 2 - 30,
                left: -20,
                x: -wElement,
                y: -hElement
            }
    
            line1.height = 80;
            line2.width = wElement / 2 + 20;
    
            line2.top = hElement + line1.height + 10;
            line2.left = wElement / 2;
    
            line2.x = 0;
            line2.y = 0;
        }
    }
    else if (element.classList.contains('pos-left')){
        element.classList.add('dot-top');
        
        if( window.innerWidth < 768){
            element.classList.remove('dot-top');
            element.classList.add('dot-bottom');

            pos = {
                top: -123,
                left: dCube / 2,
                x: - wElement / 2,
                y: -hElement
            }

            line1.height = dCube / 2 + 123;
            line2.width = wElement / 2;

            line2.top = hElement + line1.height + 10;
            line2.left = wElement - 5;
    
            line2.x = 0;
            line2.y = 0;
        }
        else if( window.innerWidth < 1024){
            pos = {
                top: dCube + 60,
                left: dCube / 2,
                x: 0,
                y: hElement
            }
            
            line1.height = 140;
            line2.width = wElement / 2;
    
            line2.top = - line1.height - 10;
            line2.left = wElement / 2;
    
            line2.x = 0;
            line2.y = 0;
        }
        else if( window.innerWidth < 1440){
            pos = {
                top: dCube,
                left: dCube + 20,
                x: 0,
                y: 0
            }
            
            line1.height = 80;
            line2.width = wElement / 2 + 20;
    
            line2.top = - line1.height - 10;
            line2.left = wElement / 2;
    
            line2.x = 0;
            line2.y = 0;
        }
        else{
            pos = {
                top: dCube,
                left: dCube + 20,
                x: 0,
                y: 0
            }
            
            line1.height = 80;
            line2.width = wElement / 2 + 20;
    
            line2.top = - line1.height - 10;
            line2.left = wElement / 2;
    
            line2.x = 0;
            line2.y = 0;
        }
    }
    
    window.addEventListener('resize', function(){
        if (element.classList.contains('pos-front')){
            element.classList.add('dot-top');
    
            if( window.innerWidth < 768){
                pos = {
                    top: dCube + 120,
                    left: dCube / 2,
                    x: - wElement / 2,
                    y: hElement
                }
    
                line1.height = 120;
                line1.duration = durLine1 + durLine2; 
    
                line2.duration = 0;
            }
            else if( window.innerWidth < 1024){
                pos = {
                    top: dCube + 60,
                    left: dCube / 2,
                    x: -wElement,
                    y: hElement
                }
    
                line1.height = 140;
                
                line2.top = - line1.height - 10;
    
                line2.left = wElement / 2;
                line2.width = wElement / 2 + 20;
        
                line2.x = 0;
                line2.y = 0;
            }
            else if( window.innerWidth < 1440){
                pos = {
                    top: dCube,
                    left: -20,
                    x: -wElement,
                    y: hElement
                }
    
                line1.height = 80;
    
                line2.top = - line1.height - 10;
                line2.left = wElement / 2;
                line2.width = wElement / 2 + 20;
        
                line2.x = 0;
                line2.y = 0;
            }
            else{
                pos = {
                    top: dCube,
                    left: -20,
                    x: -wElement,
                    y: hElement
                }
    
                line1.height = 80;
    
                line2.top = - line1.height - 10;
                line2.left = wElement / 2;
                line2.width = wElement / 2 + 20;
    
                line2.x = 0;
                line2.y = 0;
            }
        }
        else if (element.classList.contains('pos-right')){
            element.classList.add('dot-bottom');
            if( window.innerWidth < 768){
                pos = {
                    top: -123,
                    left: dCube / 2,
                    x: - wElement / 2,
                    y: -hElement
                }
    
                line1.height = dCube / 2 + 123;
                line2.width = wElement / 2;
    
                line2.top = hElement + line1.height + 10;
                line2.left = 5;
        
                line2.x = 0;
                line2.y = 0;
            }
            else if( window.innerWidth < 1024){
                pos = {
                    top: -dCube / 2,
                    left: dCube / 2,
                    x: 0,
                    y: -hElement
                }
    
                line1.height = dCube / 2 +  100;
                line2.width = wElement / 2;
        
                line2.top = hElement + line1.height + 10;
                line2.left = wElement / 2;
        
                line2.x = 0;
                line2.y = 0;
            }
            else if( window.innerWidth < 1440){
                pos = {
                    top: 0,
                    left: dCube + 20,
                    x: 0,
                    y: 0
                }
    
                line1.height = 80;
                line2.width = wElement / 2 + 20;
        
                line2.top = hElement + line1.height + 10;
                line2.left = wElement / 2;
        
                line2.x = 0;
                line2.y = 0;
            }
            else{
                pos = {
                    top: 0,
                    left: dCube + 20,
                    x: 0,
                    y: 0
                }
    
                line1.height = 80;
                line2.width = wElement / 2 + 20;
        
                line2.top = hElement + line1.height + 10;
                line2.left = wElement / 2;
        
                line2.x = 0;
                line2.y = 0;
            }
    
    
        }
        else if (element.classList.contains('pos-back')){
            element.classList.add('dot-bottom');
            if( window.innerWidth < 768){
                element.classList.remove('dot-bottom');
                element.classList.add('dot-top');
                pos = {
                    top: dCube + 120,
                    left: dCube / 2,
                    x: - wElement / 2,
                    y: hElement
                }
    
                line1.height = 120;
                line1.duration = durLine1 + durLine2;
    
                line2.duration = 0;
            }
            else if( window.innerWidth < 1024){
                pos = {
                    top: -dCube / 2,
                    left: dCube / 2,
                    x: -wElement,
                    y: -hElement
                }
        
                line1.height = dCube / 2 + 100;
                line2.width = wElement / 2;
        
                line2.top = hElement + line1.height + 10;
                line2.left = wElement / 2;
        
                line2.x = 0;
                line2.y = 0;
            }
            else if( window.innerWidth < 1440){
                pos = {
                    top: dCube / 2 - 30,
                    left: -20,
                    x: -wElement,
                    y: -hElement
                }
        
                line1.height = 80;
                line2.width = wElement / 2 + 20;
        
                line2.top = hElement + line1.height + 10;
                line2.left = wElement / 2;
        
                line2.x = 0;
                line2.y = 0;
            }
            else{
                pos = {
                    top: dCube / 2 - 30,
                    left: -20,
                    x: -wElement,
                    y: -hElement
                }
        
                line1.height = 80;
                line2.width = wElement / 2 + 20;
        
                line2.top = hElement + line1.height + 10;
                line2.left = wElement / 2;
        
                line2.x = 0;
                line2.y = 0;
            }
        }
        else if (element.classList.contains('pos-left')){
            element.classList.add('dot-top');
            
            if( window.innerWidth < 768){
                element.classList.remove('dot-top');
                element.classList.add('dot-bottom');
    
                pos = {
                    top: -123,
                    left: dCube / 2,
                    x: - wElement / 2,
                    y: -hElement
                }
    
                line1.height = dCube / 2 + 123;
                line2.width = wElement / 2;
    
                line2.top = hElement + line1.height + 10;
                line2.left = wElement - 5;
        
                line2.x = 0;
                line2.y = 0;
            }
            else if( window.innerWidth < 1024){
                pos = {
                    top: dCube + 60,
                    left: dCube / 2,
                    x: 0,
                    y: hElement
                }
                
                line1.height = 140;
                line2.width = wElement / 2;
        
                line2.top = - line1.height - 10;
                line2.left = wElement / 2;
        
                line2.x = 0;
                line2.y = 0;
            }
            else if( window.innerWidth < 1440){
                pos = {
                    top: dCube,
                    left: dCube + 20,
                    x: 0,
                    y: 0
                }
                
                line1.height = 80;
                line2.width = wElement / 2 + 20;
        
                line2.top = - line1.height - 10;
                line2.left = wElement / 2;
        
                line2.x = 0;
                line2.y = 0;
            }
            else{
                pos = {
                    top: dCube,
                    left: dCube + 20,
                    x: 0,
                    y: 0
                }
                
                line1.height = 80;
                line2.width = wElement / 2 + 20;
        
                line2.top = - line1.height - 10;
                line2.left = wElement / 2;
        
                line2.x = 0;
                line2.y = 0;
            }
        }
    })

    const obj = {
        timeline: tl, 
        element, 
        valueLine1: line1, 
        valueLine2: line2,
        position: pos
    };
    animationFace(obj);
}

// chi phí ước tính 20
function animationCenter({timeline, element, valueSpace, valueLine}:any){
    // top
    timeline.to(element, {
        duration: 0,
        onComplete: function(){
            let _this = this._targets;
            _this[0].classList.remove('actived');
        },
        onReverseComplete: function(){
            let _this = this._targets;
            _this[0].classList.remove('actived');
        }
    })

    // bắt đầu
    .to(element, {
        top: valueSpace,
        duration: 1,

        onStart: function(){
            let _this = this._targets;
            _this[0].classList.add('actived');
        },
        onComplete: function(){
            let _this = this._targets;
            _this[0].classList.add('actived');
        },
        onReverseComplete: function(){
            let _this = this._targets;
            _this[0].classList.add('actived');
        }
    })
    .to('.content-item.top p', {
        opacity: 1,
        duration: 1,
    })
    .to('.content-item.top .line-1', {
        height: valueLine,
        duration: 3
    })

    // thời gian nghỉ
    .to('.content-item.top .line-1', {
        duration: 10
    })


    // kết thúc
    .to('.content-item.top .line-1', {
        height: '0px',
        duration: 3
    })
    .to('.content-item.top p', {
        opacity: 0,
        duration: 1,
    })
    .to('.content-item.top', {
        top: 0,
        duration: 1,
        onComplete: function(){
            let _this = this._targets;
            _this[0].classList.remove('actived');
        },

        onReverseComplete: function(){
            let _this = this._targets;
            _this[0].classList.add('actived');
        }
    })
}

// tổng chi phí 20s
function animationFace({timeline, element, valueLine1, valueLine2, position}:any){
    timeline.to(element, {
        duration: 0,
        onComplete: function(){
            let _this = this._targets;
            _this[0].classList.remove('actived');
        },
        onReverseComplete: function(){
            let _this = this._targets;
            _this[0].classList.remove('actived');
        }
    })
    // 0
    .to(element, {
        top: position.top,
        left: position.left,
        x: position.x,
        y: position.y,

        duration: 0,
    })
    .to(element.querySelector('.line-2'), {
        top: valueLine2.top,
        left: valueLine2.left,
        x: valueLine2.x,
        y: valueLine2.y,
        opacity: 0,
        duration: 0
    })

    // bắt đầu
    .to(element, {
        top: position.top,
        left: position.left,
        x: position.x,
        y: position.y,
        duration: 1,
        onStart: function(){
            let _this = this._targets;
            _this[0].classList.add('actived');
        },
        onComplete: function(){
            let _this = this._targets;
            _this[0].classList.add('actived');
        },
        onReverseComplete: function(){
            let _this = this._targets;
            _this[0].classList.add('actived');
        }
    })
    .to(element.querySelector('p'), {
        opacity: 1,
        duration: 1,
    })
    // .to(element.querySelector('.line-2'), {
    //     top: valueLine2.top,
    //     left: valueLine2.left,
    //     x: valueLine2.x,
    //     y: valueLine2.y,
    //     opacity: 1,
    //     duration: 0
    // })
    .to(element.querySelector('.line-1'), {
        width: valueLine1.width,
        height: valueLine1.height,
        duration: valueLine1.duration,
    })
    
    .to(element.querySelector('.line-2'), {
        opacity: 1,
        width: valueLine2.width,
        height: valueLine2.height,
        top: valueLine2.top,
        left: valueLine2.left,
        x: valueLine2.x,
        y: valueLine2.y,
        duration: valueLine2.duration,
    })

    // thời gian nghỉ
    .to(element.querySelector('.line-2'), {
        duration: 10,
    })

    // thời gian kết thúc
    .to(element.querySelector('.line-2'), {
        width: 0,
        height: 0,
        duration: valueLine2.duration,
    })
    .to(element.querySelector('.line-1'), {
        width: 0,
        height: 0,
        duration: valueLine1.duration
    })
    .to(element.querySelector('p'), {
        opacity: 0,
        duration: 1,
    })
    .to(element, {
        duration: 1,
        onComplete: function(){
            let _this = this._targets;
            _this[0].classList.remove('actived');
        },
        onReverseComplete: function(){
            let _this = this._targets;
            _this[0].classList.add('actived');
        }
    })
}

function initFooter(){
    let elem = document.querySelector('.footer');
    if (!elem){
        return false;
    }

    gsap.to(elem.querySelector('.footer-top .logo'), {
        x: 0,
        opacity: 1,
        duration: durationDefault,
        scrollTrigger: {
            trigger: elem.querySelector('.footer-top .logo'),
            start: "top 100%",
            end: "top 90%",
            toggleClass: "gsap-actived"
        }
    })
    gsap.to(elem.querySelectorAll('.footer-navbar>ul>li'), {
        y: 0,
        stagger: 0.2,
        opacity: 1,
        duration: durationDefault,
        scrollTrigger: {
            trigger: elem.querySelectorAll('.footer-navbar>ul>li'),
            start: "top 100%",
            end: "top 90%",
            toggleClass: "gsap-actived"
        }
    })
    gsap.to(elem.querySelectorAll('.footer-information .item'), {
        x: 0,
        opacity: 1,
        stagger: 0.2,
        duration: durationDefault,
        scrollTrigger: {
            trigger: elem.querySelectorAll('.footer-information .item'),
            start: "top 100%",
            end: "top 90%",
            toggleClass: "gsap-actived"
        }
    })
    gsap.to(elem.querySelector('.footer-copyright'), {
        scale: 1,
        duration: durationDefault,
        scrollTrigger: {
            trigger: elem.querySelector('.footer'),
            start: "top 100%",
            toggleClass: "gsap-actived"
        }
    })
}