declare const Swiper: any;

export const solution = function(){
    const condition = <HTMLElement> document.querySelector('.solutions-1-thumbnail .swiper');
    
    if (condition){
        sw1(condition);
    }
}

function sw1(element:HTMLElement){
    var sw = new Swiper(element, {
        loop: true,
        effect: 'fade',

        speed: 1200,

        autoplay: {
            delay: 3200
        },

        autoHeight: true,

        pagination: {
            el: element.querySelector(".swiper-pagination"),
            clickable: true,
            dynamicBullets: true,
        },
    })
    return sw;
}