declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;

export const contact = function () {
    let elem = document.getElementById('contactPage');
    if (elem){
        handleFileInput();
        showFAQ(elem);
        initPage();
    }
};


function initPage(){
    return new window.pagination({
        selector: '.ct-3 .pagination',
        items: '.ct-3 .question-ls .item',
        scroll: {
            id: 'scrollContact'
        },
        page: 6,
    })
}


function showFAQ(element:HTMLElement){
    let ls = element.querySelectorAll('.question-ls .item');
    ls.forEach(function(item:HTMLElement){
        let name = item.querySelector('.item-question');
        if(name){
            name.addEventListener('click', function(e){
                if (item.classList.contains('showed')){
                    item.classList.remove('showed');
                }
                else{
                    window.delHandle(ls, 'showed');
                    item.classList.add('showed');
                }

            })
        }
    })
}

function handleFileInput(){
    let fileInput = <HTMLInputElement>document.getElementById('scFile');
    if (fileInput){
        fileInput.value = "";
        if (fileInput.value){
            // let li = appendFile(fileInput, fileName, size, type);

            // let prarent = fileInput.parentElement;
            // let details = prarent.querySelector('.details');
            // let ulDetail = details?.querySelector('ul');
            // if (!details){
            //     let divDetails = document.createElement('div');
            //     divDetails.classList.add('details');
        
            //     ulDetail = document.createElement('ul');
            //     divDetails.appendChild(ulDetail);
        
            //     prarent.appendChild(divDetails);
            // }

            // ulDetail.innerHTML = "";

            // ulDetail.appendChild(li);
        }

        fileInput.addEventListener('change', function(e:any){
            let target = e.target;
            let file = target.files[0];

            if (file){
                let fileName = file.name;
                let size = file.size;
                let type = file.type;

                let li = appendFile(fileInput, fileName, size, type);
                // uploadFile(fileName);

                let prarent = fileInput.parentElement;
                let details = prarent.querySelector('.details');
                let ulDetail = details?.querySelector('ul');
                if (!details){
                    let divDetails = document.createElement('div');
                    divDetails.classList.add('details');
            
                    ulDetail = document.createElement('ul');
                    divDetails.appendChild(ulDetail);
            
                    prarent.appendChild(divDetails);
                }

                ulDetail.innerHTML = "";

                ulDetail.appendChild(li);
            }
        })
    }
}

// size file
function checkFileSize(fileInfo: any) {
    const mbSize = 1024 * 1024;
    let size = fileInfo.size / mbSize;
    if (size > 10) {
        return false;
    }
    return true;
}
// accept file
function checkFileAccept(fileInput:HTMLInputElement, type: any) {
    // console.log(type);
    // console.log(fileInput.getAttribute('accept'));
    // let accept = fileInput.getAttribute('accept');
    // accept = accept.replace(/\s/g, '');

    // let arr = accept.split(',');
    // arr.forEach(function(item){
    //     if (item === "./pdf"){

    //     }
    // })
    // console.log(arr);
    

    return true;
    // if (type.split("/")[0] == "image") {
        // return true;
    // }
    // // console.log(type);
    // return false;
}

function checkFile(fileInput:HTMLInputElement, size:number, type:string){
    if (checkFileSize(size) && checkFileAccept(fileInput, type)){
        return true;
    }
    return false;
}

function appendFile(fileInput:HTMLInputElement,name:string, size:number, type:string){


    let li = document.createElement('li');
    let currentSize = size / 1024 / 1024;

    let spanName = document.createElement('span');
    spanName.classList.add('name');
    spanName.innerHTML = `${name}`;
    let spanSize = document.createElement('span');
    spanSize.classList.add('capacity');

    let mbSize = size / 1024 / 1024;
    spanSize.innerHTML = `${mbSize.toFixed(2)}MB`;

    let spanChecked = document.createElement('span');
    spanChecked.classList.add('checked');

    let spanRemove = document.createElement('span');
    spanRemove.classList.add('remove');
    spanRemove.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>`;
    
    li.appendChild(spanName);
    li.appendChild(spanSize);
    li.appendChild(spanChecked);
    li.appendChild(spanRemove);

    let iCheck = checkFile(fileInput, size, type);

    if (iCheck === true){
        li.classList.remove('error');
        li.classList.add('success');
    }else{
        li.classList.remove('success');
        li.classList.add('error');
    }

    spanRemove.addEventListener('click', function(e){
        li.remove();
        fileInput.value = "";
    })

    return li;

    // let html = `
    //     <li>
    //         <span class="name">${name}</span>
    //         <span class="capacity">${size}</span>
    //         <span class="checked">${name}</span>
    //         <span class="remove"><svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg></span>
    //     </li>
    // `;
}

// function uploadFile(newFile: any) {
//     // 1. Khởi tạo XHR để call API
//     const xhr = new XMLHttpRequest();


//     xhr.open("POST", "/api/upload.php");

//     // 5. Lắng nghe sự kiện progress lấy tỉ lệ %
//     xhr.upload.addEventListener("progress", function (event) {
//         const percent = Math.round((event.loaded / event.total) * 100);
//         console.log(percent);
//         // fileProgressing(`#${elementId}`, randomId, newFile, percent);
//     });

//     const data = new FormData();
//     data.append("file", newFile);

//     // 6. Gửi yêu cầu
//     xhr.send(data);
// }