declare const window :any;

export const header = function(){
    let eHeader = <HTMLElement>document.querySelector('.header');
    if(document.querySelector('.header')){
        handleClick(eHeader);
        scrollHeader(eHeader);
    }
}

function handleClick(eHeader:HTMLElement){
    let langOpt = eHeader.querySelector('.languages .opt');
    let link = langOpt?.querySelector('.opt__link');
    link.addEventListener('click', function(e){
        e.preventDefault();
    })

    let toggleMb = eHeader.querySelector('.toggle-mb');
    let navbar = eHeader.querySelector('.header-navbar');
    if (toggleMb && navbar){
        if (toggleMb.classList.contains('active')){
            navbar.classList.add('toggle');
        }else{
            navbar.classList.remove('toggle');
        }

        toggleMb.addEventListener('click', function(e){
            e.preventDefault();
            toggleMb.classList.toggle('active');

            if (toggleMb.classList.contains('active')){
                navbar.classList.add('toggle');
            }else{
                navbar.classList.remove('toggle');
            }
        })
    }

    let iconLs = navbar.querySelectorAll('ul>li>.icon');
    let liLs = navbar.querySelectorAll('ul>li');

    iconLs.forEach(function(iconItem){
        let parrent = iconItem.parentElement;
        iconItem.addEventListener('click', function(){
            if (parrent.classList.contains('showed')){
                parrent.classList.remove('showed');
            }else{
                window.delHandle(liLs, 'showed');
                parrent.classList.add('showed');
            }
        })
    })
}

// function scrollHeader(eHeader:HTMLElement){
//     var y = 0;
//     document.addEventListener('scroll', function(e:any){
//         y = window.scrollY;
//         if (window.scrollY > 200){
//             y = 0;
//             eHeader.classList.add('scroll');
//         }else{
//             eHeader.classList.remove('scroll');
//         }

//         eHeader.style.transform = `translateY(-${y}px)`;
//     })
// }

function scrollHeader(eHeader:HTMLElement){
    var lastScrollTop = 0;
    var timeout: any;
    let y = 0;

    function callbackTimeout(){
        var st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
            // downscroll code
            if (!document.querySelector('.idx-intro')){
                y = st;
                if (y > eHeader.clientHeight + 10){
                    y = eHeader.clientHeight + 10;
                }
                eHeader.classList.remove('scroll');
            }
        } else {
            // upscroll code
            if (window.scrollY === 0){
                if (!document.querySelector('.idx-intro')){
                    eHeader.classList.remove('scroll');
                }
            }else{
                eHeader.classList.add('scroll');
            }
            y = 0;
        }
        lastScrollTop = st <= 0 ? 0 : st;

        eHeader.style.transform = `translateY(-${y}px)`;
    }
    
    document.addEventListener("scroll", function () {
        clearTimeout(timeout);
        timeout = setTimeout(callbackTimeout, 200);
    }, false);

}